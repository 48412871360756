// add the beginning of your app entry
import 'vite/modulepreload-polyfill';
import '../css/med.scss';
import { createRoot } from 'react-dom/client';
import React from 'react';


let usernameform = document.getElementById("usernameform");
if (usernameform !== null) {

        import('./UserName.jsx').then(({UserName}) => {
            const {dataset} = usernameform;
            const root = createRoot(usernameform);
            root.render(<React.StrictMode>
                <UserName {...dataset} />
            </React.StrictMode>)
        });

}
let signupform = document.getElementById("signupform");
if (signupform !== null) {
        import('./SignUp').then(({SignUp}) => {
            const {dataset} = signupform;
            const root = createRoot(signupform);
            root.render(<React.StrictMode>
                <SignUp {...dataset} />
            </React.StrictMode>)
        });
    
}

let totpform = document.getElementById("totpform");
if (totpform !== null) {
    import('./Totp').then(({Totp}) => {
            const {dataset} = totpform;
            const root = createRoot(totpform);
            root.render(<React.StrictMode>
                <Totp {...dataset} />
            </React.StrictMode>)
        });
    
    
}


let totp = document.getElementById("totp");
if (totp !== null) {
        import('./Totp').then(({Validate}) => {
            const {dataset} = totp;
            const root = createRoot(totp);
            root.render(<React.StrictMode>
                <Validate {...dataset} />
            </React.StrictMode>)
        });
    
}



let changepassword = document.getElementById("changepassword");
if (changepassword !== null) {
    import('./ChangePassword').then(({ChangePassword}) => {
            const {dataset} = changepassword;
            const root = createRoot(changepassword);
            root.render(<React.StrictMode>
                <ChangePassword {...dataset} />
            </React.StrictMode>)
        });
}


let resetpassword = document.getElementById("resetpassword");
if (resetpassword !== null) {
    import('./ResetPassword').then(({ResetPassword}) => {
            const {dataset} = resetpassword;
            const root = createRoot(resetpassword);
            root.render(<React.StrictMode>
                <ResetPassword {...dataset} />
            </React.StrictMode>)
        });
}



